<template>
  <base-material-card icon="mdi-clipboard-text" :title="name" class="px-5 py-3">
    <div class="listing--form-container">
      <page-loading :show="loading" />
      <form @submit.prevent="submit">
        <div class="form--group row" :class="{ 'has-error': validation.hasError('province') }">
          <label class="col-12 col-lg-3" for="provinceField">
            <span>Provinsi</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-autocomplete
              id="provinceField"
              v-model="province"
              :items="provinces"
              label="Provinsi"
              :multiple="false"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              solo
              :disabled="formType !== 'ADD'"
            >
            </v-autocomplete>
            <span class="val-error">{{ validation.firstError('province') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('city') }">
          <label class="col-12 col-lg-3" for="cityField">
            <span>Kota</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-autocomplete
              id="cityField"
              v-model="city"
              :items="cities"
              label="Kota"
              :multiple="false"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              solo
              :disabled="formType !== 'ADD'"
            >
            </v-autocomplete>
            <span class="val-error">{{ validation.firstError('city') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
          <label class="col-12 col-lg-3" for="nameField">
            <span>Nama Area</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="nameField"
              v-model="name"
              type="text"
              name="name"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('name') }}</span>
          </div>
        </div>
        <div class="d-flex align-center justify-end">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ $t('area.btn.save') }}
          </v-btn>
        </div>
      </form>
    </div>
  </base-material-card>
</template>

<script>
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { mapState, mapGetters } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'area-form',
  mixins: [HelperMixin],
  props: [],
  components: {
    PageLoading,
  },
  computed: {
    ...mapState({
      formType: (state) => state.area.form.formType,
      provinces: (state) => state.area.form.provinces,
      cities: (state) => state.area.form.cities,
    }),
    ...mapGetters({}),
    loading: {
      get() {
        return this.$store.state.area.form.loading;
      },
      set(value) {
        this.$store.commit('area/form/SET_LOADING', value);
      },
    },
    name: {
      get() {
        return this.$store.state.area.form.name;
      },
      set(value) {
        this.$store.commit('area/form/SET_NAME', value);
      },
    },
    province: {
      get() {
        return this.$store.state.area.form.province;
      },
      set(value) {
        this.$store.commit('area/form/SET_PROVINCE', value);
        this.$store.dispatch('area/form/getCityByProvince', value);
        this.city = null;
      },
    },
    city: {
      get() {
        return this.$store.state.area.form.city;
      },
      set(value) {
        this.$store.commit('area/form/SET_CITY', value);
      },
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.area.name.required'));
    },
    province(value) {
      return Validator.value(value).required(this.$i18n.t('errors.area.province.required'));
    },
    city(value) {
      return Validator.value(value).required(this.$i18n.t('errors.area.city.required'));
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('area/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('area.save.successMsg'),
          'success',
        );
        this.$router.back();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
